import * as Sentry from '@sentry/nextjs';
import {
  mainnet as ethereumMainnet,
  polygon,
  bsc,
  avalanche,
  optimism,
  fantom,
  arbitrum,
  sepolia,
  base,
  // eslint-disable-next-line import/no-internal-modules
} from 'viem/chains';

import { NetworkName } from '@/lib/gql/graphql';

/**
 * Tron chain
 */
const tron = {
  id: 1000,
  idHex: '0x3E8',
};

export namespace Network {
  export enum Speed {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    Custom = 'custom',
  }

  export enum Type {
    Arbitrum = 'Arbitrum',
    Avalanche = 'Avalanche',
    Base = 'Base',
    BinanceSmartChain = 'BinanceSmartChain',
    Bitcoin = 'Bitcoin',
    Ethereum = 'Ethereum',
    Fantom = 'Fantom',
    IoNetwork = 'IoNetwork',
    Optimism = 'Optimism',
    Polygon = 'Polygon',
    Tron = 'Tron',
    TestnetBitcoin = 'TestnetBitcoin',
    TestnetIoNetwork = 'TestnetIoNetwork',
    TestnetSepoliaEthereum = 'TestnetSepoliaEthereum',
  }

  export function shouldDisplayNonce(chain: Type | null): boolean {
    if (!chain) {
      return false;
    }

    const chainsWithNonce = [
      Type.Ethereum,
      Type.BinanceSmartChain,
      Type.Polygon,
      Type.Arbitrum,
      Type.Avalanche,
      Type.Optimism,
      Type.Fantom,
      Type.Base,
      Type.TestnetSepoliaEthereum,
    ];

    return chainsWithNonce.includes(chain);
  }

  export function getNetworkIcon(network: Network.Type | null | undefined): string | null {
    switch (network) {
      case Network.Type.Arbitrum: {
        return '/network-arbitrum.svg';
      }
      case Network.Type.Avalanche: {
        return '/network-avalanche.svg';
      }
      case Network.Type.Base: {
        return '/network-base.svg';
      }
      case Network.Type.BinanceSmartChain: {
        return '/network-binance-smart-chain.svg';
      }
      case Network.Type.Bitcoin: {
        return '/network-bitcoin.svg';
      }
      case Network.Type.Ethereum: {
        return '/network-ethereum.svg';
      }
      case Network.Type.Fantom: {
        return '/network-fantom.svg';
      }
      case Network.Type.IoNetwork: {
        return '/network-io.svg';
      }
      case Network.Type.Optimism: {
        return '/network-optimism.svg';
      }
      case Network.Type.Polygon: {
        return '/network-polygon.svg';
      }
      case Network.Type.Tron: {
        return '/network-tron.svg';
      }
      case Network.Type.TestnetBitcoin: {
        return '/network-testnet-bitcoin.svg';
      }
      case Network.Type.TestnetIoNetwork: {
        return '/network-testnet-io.svg';
      }
      case Network.Type.TestnetSepoliaEthereum: {
        return '/network-testnet-sepolia-ethereum.svg';
      }
      default: {
        Sentry.captureMessage('missing icon for network', { extra: { network } });
        return null;
      }
    }
  }

  export function getDisplayName(network: Network.Type | null | undefined): string {
    if (!network) {
      return 'Unknown';
    }

    switch (network) {
      case Network.Type.Arbitrum: {
        return 'Arbitrum';
      }
      case Network.Type.Avalanche: {
        return 'Avalanche';
      }
      case Network.Type.Base: {
        return 'Base';
      }
      case Network.Type.BinanceSmartChain: {
        return 'Binance Smart Chain';
      }
      case Network.Type.Bitcoin: {
        return 'Bitcoin';
      }
      case Network.Type.Ethereum: {
        return 'Ethereum';
      }
      case Network.Type.Fantom: {
        return 'Fantom';
      }
      case Network.Type.IoNetwork: {
        return 'io.network';
      }
      case Network.Type.Optimism: {
        return 'Optimism';
      }
      case Network.Type.Polygon: {
        return 'Polygon';
      }
      case Network.Type.Tron: {
        return 'Tron';
      }
      case Network.Type.TestnetIoNetwork: {
        return '(Testnet) io.network';
      }
      case Network.Type.TestnetBitcoin: {
        return '(Testnet) Bitcoin';
      }
      case Network.Type.TestnetSepoliaEthereum: {
        return '(Sepolia) Ethereum';
      }

      default: {
        Sentry.captureMessage('missing display name for network', { extra: { network } });
        return network;
      }
    }
  }

  export function getShortName(network: Network.Type | null | undefined): NetworkName {
    switch (network) {
      case Network.Type.Arbitrum: {
        return NetworkName.Arbitrum;
      }
      case Network.Type.Avalanche: {
        return NetworkName.Avalanche;
      }
      case Network.Type.Base: {
        return NetworkName.Base;
      }
      case Network.Type.BinanceSmartChain: {
        return NetworkName.Bsc;
      }
      case Network.Type.Bitcoin: {
        return NetworkName.Btc;
      }
      case Network.Type.Ethereum: {
        return NetworkName.Eth;
      }
      case Network.Type.Fantom: {
        return NetworkName.Fantom;
      }
      case Network.Type.IoNetwork: {
        return NetworkName.Iochain;
      }
      case Network.Type.Optimism: {
        return NetworkName.Optimism;
      }
      case Network.Type.Polygon: {
        return NetworkName.Polygon;
      }
      case Network.Type.Tron: {
        return NetworkName.Tron;
      }
      case Network.Type.TestnetBitcoin: {
        return NetworkName.BtcTestnet;
      }
      case Network.Type.TestnetIoNetwork: {
        return NetworkName.IochainTestnet;
      }
      case Network.Type.TestnetSepoliaEthereum: {
        return NetworkName.EthSepolia;
      }

      default: {
        Sentry.captureMessage('missing display name for network', { extra: { network } });
        throw new Error('missing display name for network');
      }
    }
  }

  export function getChainId(network: Network.Type | null | undefined): number {
    if (!network) {
      return 0;
    }

    switch (network) {
      case Network.Type.Avalanche: {
        return avalanche.id;
      }
      case Network.Type.BinanceSmartChain: {
        return bsc.id;
      }
      case Network.Type.Arbitrum: {
        return arbitrum.id;
      }
      case Network.Type.Base: {
        return base.id;
      }
      case Network.Type.Ethereum: {
        return ethereumMainnet.id;
      }
      case Network.Type.Fantom: {
        return fantom.id;
      }
      case Network.Type.Optimism: {
        return optimism.id;
      }
      case Network.Type.Polygon: {
        return polygon.id;
      }
      case Network.Type.Tron: {
        return tron.id;
      }
      case Network.Type.TestnetSepoliaEthereum: {
        return sepolia.id;
      }

      default: {
        Sentry.captureMessage('missing chain ID for network', { extra: { network } });
        return 0;
      }
    }
  }

  export function getNetworkByChainId(chainId: string): Network.Type | null {
    switch (Number.parseInt(chainId)) {
      case avalanche.id: {
        return Network.Type.Avalanche;
      }
      case base.id: {
        return Network.Type.Base;
      }
      case bsc.id: {
        return Network.Type.BinanceSmartChain;
      }
      case arbitrum.id: {
        return Network.Type.Arbitrum;
      }
      case ethereumMainnet.id: {
        return Network.Type.Ethereum;
      }
      case fantom.id: {
        return Network.Type.Fantom;
      }
      case optimism.id: {
        return Network.Type.Optimism;
      }
      case polygon.id: {
        return Network.Type.Polygon;
      }
      case tron.id: {
        return Network.Type.Tron;
      }
      case sepolia.id: {
        return Network.Type.TestnetSepoliaEthereum;
      }

      default: {
        Sentry.captureMessage('missing network for chain ID', { extra: { chainId } });
        return null;
      }
    }
  }

  export function getNetworkTickerByChainId(chainId: string): string | null {
    switch (Number.parseInt(chainId)) {
      case avalanche.id: {
        return 'AVAX';
      }
      case base.id: {
        return 'ETH';
      }
      case polygon.id: {
        return 'MATIC';
      }
      case bsc.id: {
        return 'BNB';
      }
      case ethereumMainnet.id: {
        return 'ETH';
      }
      case fantom.id: {
        return 'FTM';
      }
      case optimism.id: {
        return 'ETH';
      }
      case arbitrum.id: {
        return 'ETH';
      }
      case tron.id: {
        return 'TRB';
      }
      case sepolia.id: {
        return 'SETH';
      }

      default: {
        Sentry.captureMessage('missing network name for chain ID', { extra: { chainId } });
        return null;
      }
    }
  }

  const order: { [k in Network.Type]: number } = {
    [Network.Type.IoNetwork]: 0,
    [Network.Type.Bitcoin]: 1,
    [Network.Type.Ethereum]: 2,
    [Network.Type.Polygon]: 3,
    [Network.Type.BinanceSmartChain]: 4,
    [Network.Type.Arbitrum]: 5,
    [Network.Type.Avalanche]: 6,
    [Network.Type.Optimism]: 7,
    [Network.Type.Fantom]: 8,
    [Network.Type.Base]: 9,
    [Network.Type.Tron]: 10,
    [Network.Type.TestnetBitcoin]: 11,
    [Network.Type.TestnetSepoliaEthereum]: 12,
    [Network.Type.TestnetIoNetwork]: 13,
  };

  export function sort(a: Network.Type, b: Network.Type): number {
    return order[a] - order[b];
  }
}
