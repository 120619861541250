import { graphql } from '@/lib/gql';

export const AssetModelFragment = graphql(`
  fragment AssetModel on Asset {
    id
    details {
      contractType
      contractAddress
    }
  }
`);
