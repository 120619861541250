export const Env = {
  application: {
    gitCommit: process.env.VERCEL_GIT_COMMIT_SHA || '',
    logoutTime: process.env.NEXT_PUBLIC_INACTIVITY_LOGOUT_TIME
      ? Number(process.env.NEXT_PUBLIC_INACTIVITY_LOGOUT_TIME)
      : undefined,
  },
  gql: {
    endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!,
  },
  //**TODO: Remove this once feature is done */
  features: {
    governance: process.env.FEATURE_FLAG_GOVERNANCE === 'true',
  },
  cognito: {
    region: process.env.NEXT_PUBLIC_COGNITO_REGION!,
    poolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID!,
    clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID!,
  },
  analytics: {
    enabled: parseBoolean(process.env.ENABLE_ANALYTICS, 'ENABLE_ANALYTICS'),
  },
  vaults: {
    minThreshold: Number.parseInt(process.env.NEXT_PUBLIC_VAULTS_MIN_THRESHOLD ?? '', 10),
    maxThreshold: Number.parseInt(process.env.NEXT_PUBLIC_VAULTS_MAX_THRESHOLD ?? '', 10),
  },
  walletConnect: {
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID!,
  },
  links: {
    app: 'https://app.iofinnet.com',
    iosAppStore: process.env.NEXT_PUBLIC_IOS_APP_URL ?? 'https://apps.apple.com/app/io-vault/id1641136546',
    privacyPolicy: 'https://www.iofinnet.com/products-privacy-policy',
    termsAndConditionsPMorUC: 'https://www.iofinnet.com/',
    termsAndConditionsVault: 'https://www.iofinnet.com/iovault-terms-and-conditions',
    termsAndConditionsFlow: 'https://www.iofinnet.com/terms-of-service-ioflow',
    helpCenter: 'https://www.iofinnet.com/',
    onChainSupport: 'https://support.iofinnet.com/helpcenter/iov/on-chain-questions',
  },
} as const;

if (!Env.gql.endpoint) {
  throw new Error(`Environment variable "NEXT_PUBLIC_GRAPHQL_ENDPOINT" was not provided`);
}

if (!Env.cognito.region) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_REGION" was not provided`);
}

if (!Env.cognito.poolId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_POOL_ID" was not provided`);
}

if (!Env.cognito.clientId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_CLIENT_ID" was not provided`);
}

if (Number.isNaN(Env.vaults.minThreshold) || Env.vaults.minThreshold <= 0) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MIN_THRESHOLD" was not provided or is not a positive number`,
  );
}

if (Number.isNaN(Env.vaults.maxThreshold) || Env.vaults.maxThreshold <= 0) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MAX_THRESHOLD" was not provided or is not a positive number`,
  );
}

if (Env.vaults.minThreshold >= Env.vaults.maxThreshold) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MAX_THRESHOLD" should be a positive number higher than "NEXT_PUBLIC_VAULTS_MIN_THRESHOLD"`,
  );
}

if (!Env.walletConnect.projectId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID" was not provided`);
}

function parseBoolean(value: string | undefined, name: string): boolean {
  if (value === 'true' || value === '1') {
    return true;
  }

  if (value === undefined || value === '' || value === '0' || value === 'false') {
    return false;
  }

  throw new Error(`Invalid value for "${name}": "${value}"`);
}
